import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasicService } from '../common/services/basic.service';
import { API_SALES_BATCH_LOAD } from '../connection.data';

@Injectable()
export class SalesBatchLoadService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_SALES_BATCH_LOAD;
    }

    toValid(item:any):Observable<string>{
        return this.http.put<any>(`${this.baseUrl}/valid/${item.id || item.get('id')}`, item).pipe(
            map((response)=>response.status)
        );
    }

    toDeleted(item:any):Observable<string>{
        return this.http.put<any>(`${this.baseUrl}/delete/${item.id || item.get('id')}`, item).pipe(
            map((response)=>response.status)
        );
    }

    validateBatch(item: any): Observable <any>{
        return this.http.post<any>(`${this.baseUrl}/validate`, item);
    }
}